html,
body {
  font-family: "Lato", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #262d3b; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Heebo", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; }

.company-card-wrapper {
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 15px;
  text-align: center; }
  .company-card-wrapper .title {
    font-size: 24px;
    font-weight: 700;
    margin: 0px 0px 10px 0px; }
  .company-card-wrapper .location {
    font-size: inherit;
    font-style: italic;
    margin: 0px; }

/* ========================================================================== */
/* Event See More Cards */
/* ========================================================================== */
.event-see-more-col a:hover {
  text-decoration: none; }

.event-see-more-card-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  padding-bottom: 20px; }
  .event-see-more-card-wrapper .card-body {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border: 1px dotted #dddbdb; }
    .event-see-more-card-wrapper .card-body .text {
      color: #56c1bb;
      font-weight: 700;
      font-size: 18px; }

/* ========================================================================== */
/* JOb See More Cards */
/* ========================================================================== */
.see-more-col a:hover {
  text-decoration: none; }

.see-more-card-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 30px 0px 10px 0px; }
  .see-more-card-wrapper .card-body {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    border: 1px dotted #dddbdb; }
    .see-more-card-wrapper .card-body .text {
      color: #56c1bb;
      font-weight: 700;
      font-size: 18px; }

/* ========================================================================== */
/* Job Cards */
/* ========================================================================== */
.job-card-col {
  margin-bottom: 40px; }
  .job-card-col a {
    color: inherit;
    text-decoration: none; }

.job-card-wrapper {
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  border-radius: 5px;
  margin-top: 30px;
  height: 100%;
  transition: margin-top 0.2s; }
  .job-card-wrapper .header {
    background-color: #56c1bb;
    position: relative;
    height: 40px;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .job-card-wrapper .header .company-logo-wrapper {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin: 0px auto;
      background-color: #fff;
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      box-shadow: 2px 1px 18px 1px rgba(0, 0, 0, 0.3); }
      .job-card-wrapper .header .company-logo-wrapper img {
        width: 100%;
        height: 80px; }
  .job-card-wrapper .body {
    padding: 30px 20px 20px 20px;
    text-align: center; }
    .job-card-wrapper .body .job-title {
      font-size: 18px;
      font-weight: 700; }
    .job-card-wrapper .body .company-title {
      font-size: 16px;
      font-style: italic;
      font-weight: 500; }

.job-card-wrapper:hover {
  margin-top: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition-delay: 0.1s; }

.navbar {
  padding: 0.6rem 1.1rem; }

.navbar-icon {
  height: 48px;
  padding: 4px;
  object-fit: fill; }

.navbar-brand {
  text-transform: uppercase;
  color: #ffffff !important;
  letter-spacing: 1.6px;
  cursor: pointer; }

.nav-link {
  cursor: pointer !important;
  color: #151d3f; }

.nav-link:hover,
.nav-link:focus {
  color: #408f8b; }

.navbar-primary .navbar-nav .nav-link {
  color: #fafafa;
  letter-spacing: 1.8px;
  font-size: 14px;
  padding: 0.6rem 1.2rem !important; }

.navbar-primary .navbar-nav .nav-link:hover,
.navbar-primary .navbar-nav .nav-link:active,
.navbar-primary .navbar-nav .nav-link:focus {
  color: #ffffff; }

/* ================================================================================================== */
/* Admin Job Posting Tabs Nav */
/* ================================================================================================== */
.job-posting-tabs .nav-item .nav-link {
  height: 100%; }

.job-posting-tabs .nav-item .nav-link.active {
  background-color: #56c1bb;
  color: #fff; }
  .job-posting-tabs .nav-item .nav-link.active .badge {
    background-color: #fff;
    color: #262d3b; }

.section {
  padding-top: 60px;
  padding-bottom: 60px; }

.rich-text-viewer {
  overflow-x: hidden; }

.margin-auto {
  margin: 0px auto;
  display: block; }

/* ================================================================================================== */
/* Paper Containers */
/* ================================================================================================== */
.paper {
  padding: 40px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  border-radius: 0.26rem; }

.paper-sm {
  padding: 30px 20px;
  background: #ffffff; }

.paper-card {
  border-radius: 6px;
  background: #ffffff;
  margin: 20px 0px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21); }
  .paper-card .header {
    padding: 25px 40px 15px 40px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 0;
    margin-top: 10px;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0; }
    .paper-card .header .title {
      font-size: 22px;
      font-weight: 500;
      margin: 0px; }
  .paper-card .body {
    padding: 10px 40px 20px 40px; }

/* ================================================================================================== */
/* Background Styles */
/* ================================================================================================== */
.bg-light-blue {
  background: #e4eefa; }

.bg-primary {
  background-color: #56c1bb !important; }

.bg-secondary {
  background-color: #151d3f !important; }

/* ================================================================================================== */
/* Program Card */
/* ================================================================================================== */
.program-card {
  padding-top: 20px;
  padding-bottom: 20px; }
  .program-card img {
    width: 100%;
    height: 200px;
    object-fit: cover; }
  .program-card .title {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 3px;
    padding: 20px 10px;
    display: block;
    text-align: center;
    text-transform: uppercase; }

/* ================================================================================================== */
/* Event Card */
/* ================================================================================================== */
.event-card-col {
  margin-bottom: 30px; }

.event-card-link {
  color: inherit; }

.event-card-link:hover {
  color: inherit;
  text-decoration: none; }
  .event-card-link:hover .event-card {
    border: 0;
    border-radius: 0.2rem;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.24); }

.event-card {
  background: #ffffff;
  border-radius: 3px;
  border-radius: 0.2rem;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.24);
  animation-duration: 2s; }
  .event-card .header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    height: 160px;
    overflow: hidden; }
    .event-card .header img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: transform 0.4s; }
    .event-card .header img:hover {
      transform: scale(1.2); }
  .event-card .body {
    padding: 10px 15px; }
    .event-card .body .date {
      font-size: 16px;
      font-family: "Lato", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      margin: 0px; }
    .event-card .body .title {
      font-size: 20px;
      font-weight: 700;
      margin: 5px 0px 5px 0px; }
    .event-card .body .location {
      font-size: 14px;
      font-weight: 500;
      font-family: "Lato", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      margin-bottom: 0px; }

/* ================================================================================================== */
/* Event Details Page */
/* ================================================================================================== */
.event-cover {
  height: 500px;
  padding: 0px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -2; }
  .event-cover img {
    width: 100%;
    object-fit: cover;
    filter: blur(6px) grayscale(0.4);
    -webkit-filter: blur(6px) grayscale(0.4); }

.event-details {
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  overflow: hidden; }
  .event-details .event-img {
    height: 320px;
    overflow: hidden; }
    .event-details .event-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .event-details .main-content {
    padding: 40px 20px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .event-details .main-content .title {
      font-family: "Raleway", sans-serif;
      font-size: 24px;
      font-weight: 700; }
    .event-details .main-content .date {
      font-family: "Lato", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-size: 16px; }
    .event-details .main-content .price {
      font-family: "Lato", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-size: 20px;
      font-weight: 300; }

/* ================================================================================================== */
/* Dashboard Cards */
/* ================================================================================================== */
.dashboard-card {
  background-color: #ffffff;
  padding: 15px 30px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.24); }
  .dashboard-card .title {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0px; }
  .dashboard-card .count {
    font-size: 24px;
    font-weight: 700;
    margin: 0px; }
  .dashboard-card .icon {
    float: right;
    font-size: 1.4em;
    line-height: normal;
    opacity: 0.4;
    margin-bottom: 5px; }

.dashboard-card.primary {
  border-left: 10px solid #56c1bb; }
  .dashboard-card.primary .title {
    color: #56c1bb; }
  .dashboard-card.primary .icon {
    color: #408f8b; }

.dashboard-card.secondary {
  border-left: 10px solid #151d3f; }
  .dashboard-card.secondary .title {
    color: #151d3f; }
  .dashboard-card.secondary .icon {
    color: #151d3f; }

.dashboard-card.success {
  border-left: 10px solid #da5773; }
  .dashboard-card.success .title {
    color: #da5773; }
  .dashboard-card.success .icon {
    color: #da5773; }

/* ================================================================================================== */
/* Dashboard Styles */
/* ================================================================================================== */
.dashboard-wrapper {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: "dashboard-sidebar dashboard-header" "dashboard-sidebar dashboard-main";
  height: 100vh;
  overflow: hidden; }

.dashboard-header {
  grid-area: dashboard-header;
  box-sizing: border-box; }

.dashboard-sidebar {
  grid-area: dashboard-sidebar;
  box-sizing: border-box; }

.dashboard-main {
  grid-area: dashboard-main;
  box-sizing: border-box;
  background: #e4eefa;
  overflow-y: auto; }

@media (max-width: 992px) {
  .dashboard-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "dashboard-header" "dashboard-main" "dashboard-sidebar"; }
  .dashboard-sidebar {
    background: #56c1bb;
    display: flex; }
    .dashboard-sidebar .sidebar-brand {
      display: none; }
    .dashboard-sidebar .sidebar-list-wrapper {
      width: 100%; }
      .dashboard-sidebar .sidebar-list-wrapper .sidebar-list {
        display: flex;
        flex-direction: row;
        padding: 0px; }
        .dashboard-sidebar .sidebar-list-wrapper .sidebar-list .sidebar-item {
          flex: 1;
          box-sizing: border-box; }
          .dashboard-sidebar .sidebar-list-wrapper .sidebar-list .sidebar-item a {
            margin-bottom: 0px;
            text-align: center;
            color: #ffffff;
            height: 100%; }
          .dashboard-sidebar .sidebar-list-wrapper .sidebar-list .sidebar-item .active {
            border-right: none;
            border-bottom: 4px solid #257475; }
  .dashboard-header {
    grid-area: dashboard-header;
    box-sizing: border-box; }
  .dashboard-main {
    grid-area: dashboard-main;
    box-sizing: border-box;
    background: #e4eefa;
    overflow-y: auto; } }

@media (max-width: 576px) {
  .navbar-icon {
    height: 40px; }
  .sidebar-item a svg {
    display: block;
    margin: 0px auto 2px auto; } }

.navbar-toggle-btn {
  color: #ffffff;
  margin-right: 20px; }

.navbar-toggle-btn:active {
  outline: none; }

/* ================================================================================================== */
/* Footer Styles */
/* ================================================================================================== */
.footer-simple {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #56c1bb; }

/* ================================================================================================== */
/* Dropzone CV Wrapper */
/* ================================================================================================== */
.dropzone-cv-wrapper {
  width: 100%;
  height: auto;
  min-height: 100px;
  background: #fff;
  border: 1px dashed #dddbdb;
  padding: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .dropzone-cv-wrapper .text {
    width: 100%; }
  .dropzone-cv-wrapper .cv-item {
    width: 100%; }

/* ================================================================================================== */
/* Dropzone picture Wrapper */
/* ================================================================================================== */
.dropzone-picture-wrapper {
  width: 200px;
  height: 200px;
  background: #fff;
  border: 1px dashed #dddbdb;
  display: flex;
  align-items: center; }
  .dropzone-picture-wrapper .text {
    text-align: center;
    font-weight: 500; }
  .dropzone-picture-wrapper .img-inner {
    width: 200px;
    height: 200px; }
    .dropzone-picture-wrapper .img-inner img {
      object-fit: cover;
      height: 85%;
      width: 100%; }
    .dropzone-picture-wrapper .img-inner .text {
      padding-top: 2px;
      height: 15%;
      background: #408f8b;
      color: #fff;
      text-align: center; }

/* ================================================================================================== */
/* Profile Links */
/* ================================================================================================== */
@media (max-width: 576px) {
  .nav-links-list {
    display: flex;
    flex-direction: row; }
    .nav-links-list .disabled {
      display: none; }
    .nav-links-list a {
      flex: 1;
      text-align: center; }
  .paper {
    padding: 20px 10px; } }

/* ================================================================================================== */
/* Job Details Wrapper */
/* ================================================================================================== */
.job-detail-wrapper {
  margin: 10px 0px;
  background-color: #fff; }
  .job-detail-wrapper .header {
    padding: 20px;
    display: flex; }
    .job-detail-wrapper .header .logo {
      width: 160px;
      height: 160px;
      border: 1px solid #dddbdb;
      border-radius: 4px; }
      .job-detail-wrapper .header .logo img {
        width: 150px;
        height: 150px;
        margin: 5px auto;
        display: block; }
    .job-detail-wrapper .header .job-info {
      flex: 1;
      padding: 20px 20px 0px 20px; }
      .job-detail-wrapper .header .job-info .title {
        font-size: 30px;
        font-weight: 700; }
      .job-detail-wrapper .header .job-info .company-link:hover {
        color: #878787 !important; }
      .job-detail-wrapper .header .job-info .company {
        font-size: 20px;
        font-weight: 500;
        color: #878787; }
  .job-detail-wrapper .body {
    padding: 40px 40px 60px 40px; }
    .job-detail-wrapper .body .details-wrapper {
      display: flex; }
      .job-detail-wrapper .body .details-wrapper .description {
        flex: 3; }
      .job-detail-wrapper .body .details-wrapper .details {
        flex: 1; }

.form-control {
  width: 100%;
  height: calc(1.8rem + 1.2rem + 2px);
  padding: 0.8rem 1.2rem;
  border: 1px solid #dddbdb;
  border-radius: 3px;
  font-size: 16px;
  line-height: normal;
  outline: none;
  box-sizing: border-box; }

.form-control:focus {
  border-color: #408f8b;
  border-width: 0.1em;
  outline: none;
  box-shadow: none; }

.form-control-sm {
  height: calc(1.6em + 0.8rem + 2px);
  padding: 0.4rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.autocomplete-menu-wrapper {
  position: relative; }
  .autocomplete-menu-wrapper .autocomplete-menu {
    position: absolute;
    width: 100%; }

.dropdown-select {
  border: 1px solid #dddbdb !important;
  background-color: #fff !important;
  color: #262d3b;
  line-height: normal;
  padding: 0.8rem 1.2rem;
  height: calc(1.8rem + 1.2rem + 2px); }

.dropdown-select:hover,
.dropdown-select:active,
.dropdown-select:focus {
  color: #262d3b !important;
  box-shadow: none; }

.dropdown-select:active:focus {
  box-shadow: 0 0 0 0.2rem #151d3f; }

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none; }

/* ====================================== */
/* Input date field custom styles */
/* ====================================== */
input[type="date"] {
  position: relative; }

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\F073";
  color: #555;
  padding: 0 5px; }

/* change color of symbol on hover */
input[type="date"]:hover:after {
  color: #333; }

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent; }

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
  z-index: 1; }

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
  z-index: 1; }

.text-bold {
  font-weight: 700; }

.bordered {
  border: 1px solid #dddbdb; }

.bordered-tb {
  border-top: 1px solid #dddbdb;
  border-bottom: 1px solid #dddbdb; }

.paper-title {
  font-size: 28px;
  font-weight: 700;
  font-family: "Lato", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; }

.title-sm {
  font-size: 16px;
  letter-spacing: 2px;
  font-style: italic; }

.no-shadow {
  box-shadow: none; }

.img-page-icon {
  height: 150px;
  object-fit: cover; }

.no-caret .dropdown-toggle:after {
  display: none; }

.dropdown-item {
  cursor: pointer; }

.company-logo {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 10px; }

.no-border {
  border: none !important; }

/* ========================================================================== */
/* Page typography */
/* ========================================================================== */
.page-title {
  font-weight: 700;
  font-size: 26px;
  margin-left: 10px;
  margin-bottom: 10px; }

.page-description {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 20px; }

/* ========================================================================== */
/* badges */
/* ========================================================================== */
.badge {
  padding: 0.4rem 0.6rem;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: 12px !important;
  white-space: pre-wrap; }

.badge-primary {
  color: #fff;
  background-color: #56c1bb; }

.badge-warning {
  background-color: #e8c11d; }

.badge-sm {
  padding: 5px 7px; }

/* ========================================================================== */
/* Text Colors */
/* ========================================================================== */
.text-primary {
  color: #56c1bb !important; }

.text-primary-dark {
  color: #408f8b !important; }

.text-secondary {
  color: #151d3f !important; }

/* ========================================================================== */
/* Font Sizes */
/* ========================================================================== */
.fs-12 {
  font-size: 12px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-16 {
  font-size: 16px !important; }

.fs-20 {
  font-size: 20px !important; }

.fs-24 {
  font-size: 24px !important; }

.fs-28 {
  font-size: 28px !important; }

.fs-32 {
  font-size: 32px !important; }

.fs-36 {
  font-size: 36px !important; }

.dropdown-item.active,
.dropdown-item:active {
  color: #fff !important;
  text-decoration: none;
  background-color: #408f8b; }

/* ========================================================================== */
/* List Active styles */
/* ========================================================================== */
.list-group-item.active {
  background: #408f8b;
  border-color: #56c1bb;
  color: #fff !important; }

/* ========================================================================== */
/* Data Table */
/* ========================================================================== */
.data-table-wrapper div {
  table-layout: fixed; }

.data-table-wrapper .rdt_TableHead * {
  font-size: 16px;
  font-weight: 700; }

.data-table-wrapper .rdt_TableBody * {
  font-size: 14px; }

.data-table-wrapper .ksKbuS {
  font-weight: 700; }

/* ========================================================================== */
/* breadcrumb-wrapper */
/* ========================================================================== */
.breadcrumb-wrapper {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.21);
  border-radius: 4px; }
  .breadcrumb-wrapper .breadcrumb {
    margin-bottom: 0px;
    padding: 0px 20px;
    background-color: inherit; }
    .breadcrumb-wrapper .breadcrumb .breadcrumb-item a {
      color: #56c1bb;
      font-weight: 700; }
    .breadcrumb-wrapper .breadcrumb .breadcrumb-item a:hover,
    .breadcrumb-wrapper .breadcrumb .breadcrumb-item a:active,
    .breadcrumb-wrapper .breadcrumb .breadcrumb-item a:focus {
      color: #408f8b; }
    .breadcrumb-wrapper .breadcrumb .breadcrumb-item .active {
      font-weight: 700;
      text-decoration: none;
      color: #408f8b; }

/* ========================================================================== */
/* Side bar styles */
/* ========================================================================== */
.sidebar-brand {
  font-size: 24px;
  font-weight: 500;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 0; }
  .sidebar-brand img {
    height: 52px;
    margin: 5px auto 10px 5px;
    object-fit: contain; }

.sidebar-list {
  list-style: none;
  margin: 0px;
  padding: 20px 0px; }

.sidebar-item {
  box-sizing: border-box; }
  .sidebar-item .active {
    background: #56c1bb;
    color: #ffffff;
    border-right: 6px solid #257475;
    padding-left: 18px;
    padding-right: 10px; }

.sidebar-item a {
  text-decoration: none;
  color: #262d3b;
  width: 100%;
  display: block;
  padding: 10px 15px;
  margin-bottom: 10px; }

.sidebar-item a span {
  padding: 0px 10px; }

/* ========================================================================== */
/* Horizontal timeline */
/* ========================================================================== */
.horizontal-timeline .timeline {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  margin: 0px; }
  .horizontal-timeline .timeline li {
    width: 40px;
    height: 40px;
    background-color: #99ccc9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #fff; }
  .horizontal-timeline .timeline li:after {
    display: inline-block;
    content: "";
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    left: 50%;
    margin-left: -3px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }

.btn {
  border-radius: 2px;
  padding: 0.6rem 1.2rem;
  font-size: 14px;
  line-height: 1em;
  font-weight: 400;
  border-radius: 0.16rem; }

.btn:active {
  outline: none; }

.btn:focus {
  box-shadow: none; }

.btn-sm {
  padding: 0.25rem 0.7rem;
  font-size: 0.875rem;
  line-height: 1.2em;
  border-radius: 0.2rem; }

/* ================================================================================================== */
/* buttons sizes */
/* ================================================================================================== */
.btn-lg {
  padding: 10px 24px; }

/* ================================================================================================== */
/* primary button */
/* ================================================================================================== */
.btn-primary {
  background-color: #56c1bb;
  border: 1px solid #99ccc9; }

.btn-primary:hover {
  background-color: #257475;
  border: 1px solid #99ccc9;
  color: #fafafa; }

.btn-primary:active,
.btn-primary:focus {
  outline: none;
  background-color: #408f8b; }

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #99ccc9; }

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fafafa;
  background-color: #408f8b;
  border-color: #99ccc9; }

/* ================================================================================================== */
/* Secondary Button */
/* ================================================================================================== */
.btn-secondary {
  background-color: #151d3f;
  border: none; }

.btn-secondary:hover {
  background-color: #363c5c; }

.btn-secondary:active,
.btn-secondary:focus {
  outline: none;
  background-color: #363c5c; }

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none; }

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fafafa;
  background-color: #363c5c; }

/* ================================================================================================== */
/* Info Button */
/* ================================================================================================== */
.btn-info {
  background-color: #da5773;
  border: none; }

.btn-info:hover {
  background-color: #a64258; }

.btn-info:active,
.btn-info:focus {
  outline: none;
  background-color: #a64258; }

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none; }

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fafafa;
  background-color: #a64258; }

/* ================================================================================================== */
/* Icon Buttons */
/* ================================================================================================== */
.btn-icon {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  padding: 5px;
  line-height: 2.4em;
  font-size: 1em;
  border: 1px solid #dddbdb; }

/* ================================================================================================== */
/* Custom Buttons */
/* ================================================================================================== */
.btn-transparent {
  background-color: transparent;
  background: transparent;
  border: none;
  outline: none; }

.btn-transparent:hover,
.btn-transparent:focus,
.btn-transparent:active {
  background-color: transparent !important;
  background: transparent;
  border: none !important;
  outline: none !important;
  box-shadow: none !important; }

.btn-transparent-bordered {
  border: 2px solid #fafafa;
  background-color: transparent;
  color: #fafafa; }

.btn-transparent-bordered:hover,
.btn-transparent-bordered:focus,
.btn-transparent-bordered:active {
  border: 2px solid #fafafa;
  background-color: #fafafa !important; }
  .btn-transparent-bordered:hover a,
  .btn-transparent-bordered:focus a,
  .btn-transparent-bordered:active a {
    color: #262d3b !important; }

.btn-rounded {
  border-radius: 50px; }

/* ================================================================================================== */
/* Field Position Toggle Group */
/* ================================================================================================== */
.fp-toggle-group {
  display: block; }
  .fp-toggle-group .btn {
    border-radius: 50px !important;
    margin: 5px 10px; }
  .fp-toggle-group .btn.active {
    background-color: #56c1bb !important;
    color: #ffffff !important;
    outline: none;
    box-shadow: none; }

.message-alert-wrapper {
  border-radius: 8px; }
  .message-alert-wrapper .modal-dialog .modal-content {
    border: none;
    border-radius: 8px;
    text-align: center; }
    .message-alert-wrapper .modal-dialog .modal-content .modal-header {
      border: none;
      padding: 10px 20px; }
    .message-alert-wrapper .modal-dialog .modal-content .modal-body {
      padding-top: 0px;
      text-align: center; }
    .message-alert-wrapper .modal-dialog .modal-content .modal-footer {
      border: none;
      text-align: center;
      justify-content: center; }
